<template>
    <div class="layout">
        <div class="page-4 page">
            <Header :activeIndex="'2'"></Header>
            <div :class="showBG?'page-section bg':'page-section'">
                <video autoplay loop muted :class="vdaH?'hide':''">
                    <source src="https://jy-datav-images.oss-cn-shanghai.aliyuncs.com/jinfucloud/%E8%B6%85%E7%BA%A7%E8%AE%A1%E7%AE%97%E4%BA%91.mp4" type="video/mp4">
                    您的浏览器不支持Video标签。
                </video>
                <div :class="vdaH?'section-tab H':'section-tab'">
                    <div class="section-center">   
                        <div class="section-cont first-section">
                            <div class="banner-txt">
                                <div class="l">
                                    <h2>计算资源层</h2>
                                    <h4>超级计算云 + 数金云</h4>
                                </div>
                                <!-- <p>计算资源层提供基于云原生的多云方案，包括国家超算中心广州中心的超算云、国内三大运营商的云服务。多云方案为基础设施平台的数据治理层提供安全合规的云环境；超算云服务具有独特的高压缩存储技术，灵活调用的千万个超算节点，能够很好的满足智能分析引擎对算力的要求，是智能引擎层的算力保证；同时让地方金融机构只需低成本即可享用应用响应层的公共应用系统服务。基于云原生的多云方案基础设施能力，能够优化组合各云服务优势、效率与成本，最终实现综合成本、安全合规、运行效率整体最优。</p> -->
                
                            </div>
                        </div>  
                    </div>   
                </div>
                <div class="section-sub-tab" id="section-sub-tab">
                    <div class="sec-bot">
                        <div class="section-center">
                            <p style="text-indent: 35px;font-size:1rem;line-height: 1.8;">计算资源层提供基于云原生的多云方案，包括国家超算中心广州中心的超算云、联通数金云服务。多云方案为基础设施平台的数据治理层提供安全合规的云环境；超算云服务具有独特的高压缩存储技术，灵活调用的千万个超算节点，能够很好的满足智能分析引擎对算力的要求，是智能引擎层的算力保证；同时让地方金融机构只需低成本即可享用应用响应层的公共应用系统服务。基于云原生的多云方案基础设施能力，能够优化组合各云服务优势、效率与成本，最终实现综合成本、安全合规、运行效率整体最优。</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    </div>
</template>

<script>
import { Header, Footer } from "../common/common.js";

  export default {
    components: {
        Header,
        Footer,
    },
    name: '',
    data () {
      return {
        showBG: false,
        vdaH: false
      }
    },
    mounted() {
        this.$nextTick(()=>{
            setTimeout(()=>{
                this.showBG = true
            },1000)
        })
        this.browserRedirect()
        const that = this;
        window.addEventListener('resize',function(){
            that.browserRedirect()
        },false)
    },
    methods: {
        browserRedirect() {
            var sUserAgent = navigator.userAgent.toLowerCase();
            // alert(sUserAgent)
            
            if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(sUserAgent)) {
                //跳转移动端页面
                this.vdaH = true
            } else {
                //跳转pc端页面
                this.vdaH = false
            }
        }
    }
        

  }
</script>

<style>
    .page-4 .page-section {
        position: relative;
        /* width: 100%; */
        /* max-height: 100vh; */
        overflow: hidden;
        min-width: 1200px;
        
    }
    .page-4 .page-section.bg {
        background: url('../../assets/img/BG7.png') no-repeat;
        background-size: 100% 100%;
    }
    .page-4 video {
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        -o-object-fit: fill;
        object-fit: fill;
        width: auto;
        height: auto;
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
    }
    .page-4 .section-tab {
        position: relative;
        /* width: 100%; */
        /* height: 100vh; */
        height: 86.7592vh;
        color: #fff;
        /* background-size: 100% 100%; */
        overflow: hidden;
        min-width: 1200px;
        /* background: url('../../assets/img/BG7.png') no-repeat; */
        /* background: red; */
    }
    .page-4 .section-tab.H {
        height: 92vh;
    }
    .page-4 .page-section .section-center , .page-4 .page-section .section-cont {
        width: 100%;
        height: 100%;
    }
    .page-4 .page-section .banner-txt {
        width: 100%;
        height: 100%;
        position: relative;
    }
    .page-4 .page-section .l {
        width: 1200px;
        position: absolute;
        left: 50%;
        top: 30%;
        transform: translateX(-50%);
    } 
    .page-4 .page-section .l h2 {
        font-size: 30px;
        font-weight: 700;
        color: #fff;
        margin-bottom: 15px;
    }
    .page-4 .page-section .l h4 {
        font-size: 24px;
        font-weight: 400;
        color: #fff;
        margin-top: 0;
    }
    .page-4 .page-section .section-sub-tab p {
        text-indent: 35px;
        width: 1200px;
        position: absolute;
        left: 50%;
        bottom: 3%;
        transform: translateX(-50%);
        color: #fff;
    }
    .page-section .section-sub-tab {
        background: unset;
       
    }
    .page-4 .page-section .section-sub-tab {
        /* height: 11.4889vh; */
    }
</style>

