<template>
    <div class="layout">
        <div class="page-1 page">
            <Header :activeIndex="'2'"></Header>
            <div class="page-section">
                <div class="section-tab">
                    <div class="section-center">   
                        <h4 class="page-tit">智能引擎层</h4>                     
                        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">                            
                            <el-tab-pane label="知识图谱+机器学习" name="first">
                                <div class="section-cont first-section">
                                    <div class="sec-top">
                                        <div class="banner-img-1"></div>
                                        <p>基于“知识图谱+机器学习”的智能分析引擎，通过知识图谱“实体识别”、“关系挖掘” 构建关系图谱网络，结合“社群发现”、“传导路径”等图算法，分析关系路径的风险传导性、团体风险性，通过全局视角、多维度穿透性识别潜在关系和关联风险。让地方金融行业机构、行业监管部门决策分析从传统的经验驱动升级到“大数据+AI智能”驱动，让风险管理、预测结果更科学更精准，更具解释性。</p>
                                        
                                    </div>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="智能决策库" name="second">
                                <div class="section-cont second-section">
                                    <div class="sec-top">
                                        <div class="banner-img-2"></div>
                                        <p>智能决策库，集成了基于“知识图谱+机器学习”训练的各种模型算法。并通过可视化的智能决策引擎工具，将模型算法运用到具体的业务决策流程中，机构业务人员可随时根据业务发展变化灵活调整决策流程，在线对新旧决策流程进行生成环境的AB测试，让新旧策略流程效果对比结果更有说服力。</p>
                                    </div>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                    <div class="down-btn">
                        <a href="#section-sub-tab"><i class="el-icon-bottom"></i></a>
                    </div>
                </div>
                <div class="section-sub-tab" v-if="activeName=='first'" id="section-sub-tab">
                    <div class="sec-bot">
                        <div class="section-center">
                            <h4 class="sec-tit"><i class="el-icon-caret-right"></i><span>应用场景</span></h4>
                            <el-tabs v-model="activeName1" type="card" @tab-click="handleClick1">                            
                                <el-tab-pane v-for="(item,index) in subTab1" :key="index" :label="item.name" :name="String(index)">
                                    <div class="flex">
                                        <div class="l">
                                            <div>
                                                <h4>业务场景：</h4>
                                                <p>{{item.scence}}</p>
                                            </div>
                                            <div>
                                                <h4>业务价值：</h4>
                                                <p>{{item.value}}</p>
                                            </div>
                                        </div>
                                        <div class="r">
                                            <img v-if="!(Array.isArray(item.img))" :src="item.img" alt="">
                                            <div v-else>
                                                <el-carousel height="39vh" indicator-position="outside" v-if="activeName1==String(3)">
                                                    <el-carousel-item v-for="(it,index) in item.img" :key="index">
                                                        <img :src="it" alt="">
                                                    </el-carousel-item>
                                                </el-carousel>
                                            </div>
                                        </div>
                                    </div>
                                </el-tab-pane>
                            </el-tabs>
                        </div>
                    </div>
                </div>
                <div class="section-sub-tab" v-if="activeName=='second'" id="section-sub-tab">
                    <div class="sec-bot">
                        <div class="section-center">
                            <div style="text-align: center;margin-top: -5.5556vh;">
                                <img src="../../assets/img/page1-5.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    </div>
</template>

<script>
import { Header, Footer } from "../common/common.js";
import B1 from '../../assets/img/page1-1.png'
import B2 from '../../assets/img/page1-2.png'
import B3 from '../../assets/img/page1-3.png'
import B4 from '../../assets/img/page1-4.png'
import B6 from '../../assets/img/page1-6.png'

  export default {
    components: {
        Header,
        Footer,
    },
    name: '',
    data () {
      return {
        activeName: 'first',
        subTab: [],
        activeName1: '0',
        subTab1: [
            {
                name: '多头借贷',
                scence: '因地方金融机构之间信息未互通，这种信息差异使得地方金融借贷市场长期存在多头借贷情况。对地方金融机构风控能力和运营能力带来较大挑战，还存在地方金融风险堆积的情况，易形成关联性风险影响地方金融行业稳定。',
                value: '通过知识图谱可对借款主体进行分析，挖掘潜在的关联关系，帮助地方金融机构识别可能性的多头借贷风险。提升风险管理水平。',
                img: B3
            },
            {
                name: '团伙欺诈',
                scence: '金融行业的欺诈通过信息不对称和间接层，来实施欺诈，针对增设空壳公司，通过多个壳公司套取地方金融机构借贷金额。而金融机构对团伙欺诈以及全局关系维度信贷评估缺乏针对性的有效风险控制手段。若不加以识别和控制，将导致地方金融风险堆积，出现关联风险和系统性风险。',
                value: '将现实世界中的业务场景转化为更好同时被人与机器所理解的图结构的形式，对图结果进行智能分析，利用社群发现算法挖掘可疑的关联团伙，并结合实际情况对关联团伙进行是否为“欺诈团伙”的判断。',
                img: B2
            },
            {
                name: '智能营销',
                scence: '企业营销推广前市场调研缺失或调研难、自有数据库混乱难以统一、公司内部数据不透明等问题，导致在推广初期销售难，潜在客户识别难。',
                value: '通过分析客户风险与价值，将客群分类。通过集团关系、担保、资金来往、上下游关系挖掘潜在客户。通过企业客户的地域、行业、规模等特征有针对性的推荐合适的产品，并对其进行个性化营销，对产品营销过程进行管理。',
                img: B1
            },
            {
                name: '资产追查',
                scence: '有很多人在经营企业时，会通过多层控股或委托控股等情况进行企业经营，在企业经营不善时，这种多层控股或委托控股的情况就成为债务人躲避债务的方式，将资产进行隐匿，使债权人无法追回正常债务，形成死债，形成不良资产。',
                value: '对公知识图谱可以挖掘出客户的潜在关联关系网络，从而拓宽客户资产追查的范围，追回客户隐匿的资产，从而帮助银行和地方金融机构减少损失。',
                img: [B4,B6]
            }
        ],
      }
    },
    mounted() {
        this.$nextTick(()=>{
            if(this.$route.query.sec){
                var sec = this.$route.query.sec
                this.activeName = sec
            }
        })
    },
    methods: {
        handleClick() {
        },
        handleClick1() {
        }
    }
        

  }
</script>

<style>
    
    .page-1 .page-section .section-tab {  
        background: url('../../assets/img/BG.jpg') no-repeat;
        background-size: 100% 100%;
        background-position: center;
        min-width: 1200px;
    }
    .page-1 .page-section .section-sub-tab {
        background: url('../../assets/img/BG2.png') no-repeat;
        background-size: 100% 100%;
        background-position: center;
        min-width: 1200px;
        /* padding: 40px 0 60px; */
    }
    .page-1 .page-section .section-sub-tab .sec-bot .r img {
        max-height: 38.6111vh;
    }
    .page-1 .page-section .section-sub-tab .sec-bot .el-tab-pane:nth-of-type(1) .r img {
        max-height: 32.1296vh;
    }

    .page-1 .banner-img-1 {
        height: 48.4259VH;
        background: url('../../assets/img/page1-banner.png') no-repeat;
        background-size: contain;
        background-position: center;
    }
    .page-1 .banner-img-2 {
        height: 49.8148VH;
        background: url('../../assets/img/page1-banner2.png') no-repeat;
        background-size: contain;
        background-position: center;
    }
    #pane-3 .l {
        width: 40%;
    }
    #pane-3 .r {
        width: 52%;
    }

    .page-1 .page-section .section-sub-tab .sec-bot .l{
        width: 45%;
        padding: 0;
    }
    .page-1 .page-section .section-sub-tab .sec-bot .r {
        width: 55%;
        max-width: 55%;
        padding: 0;
    }
    .page-1 .page-section .el-carousel__item {
        display: flex;
        align-items: center;
    }

</style>

