import Vue from 'vue'
import VueRouter from 'vue-router';


import Index from '../components/index.vue'
import page1 from '../components/page/page1.vue'
import page2 from '../components/page/page2.vue'
import page3 from '../components/page/page3.vue'
import page4 from '../components/page/page4.vue'
import aboutus from '../components/page/aboutus.vue'


Vue.use(VueRouter);

export default new VueRouter({
    mode: 'history',
    routes: [
        // { path: '/index/:section',component: Index},
        { path: '/index',component: Index},
        { path: '/',component: Index},
        { path: '/page1',component: page1},
        { path: '/page2',component: page2},
        { path: '/page3',component: page3},
        { path: '/page4',component: page4},
        { path: '/aboutus',component: aboutus}
    ]
})