<template>
    <div class="layout">
        <div class="page-3 page">
            <Header :activeIndex="'2'"></Header>
            <div class="page-section">
                <div class="section-tab">
                    <div class="section-center">   
                        <h4 class="page-tit">数据治理层</h4>                     
                        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">                            
                            <el-tab-pane label="数据治理" name="first">
                                <div class="section-cont first-section">
                                    <div class="sec-top">
                                        <div class="banner-img-1"></div>
                                        <p>平台建立完善数据治理服务，以大数据处理技术为支撑，以数据规划与管理咨询方法为指导，以数据聚合、治理、融合、服务为核心，以区块链和隐私计算新技术为安全流通保障，为地方金融行业提供有效的数据资产沉淀和安全合规共享工具，服务数据资产管理、数据治理、数据中台落地等企业诉求。形成业务沉淀数据，挖掘及发现数据价值，数据反哺驱动行业监管、业务决策与增长的良性循环。</p>
                                    </div>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="数据资源共享" name="second">
                                <div class="section-cont second-section">
                                    <div class="sec-top">
                                        <div class="banner-img-2"></div>
                                        <p>地方金融数字化基础设施平台，整合五链协同“征信链”地方金融信用信息基础数据资源库，利用区块链、隐私计算技术，逐步实现与政务数据、监管数据、司法数据、商业数据、地方金融机构间数据的安全共享，协助监管部门规范地方金融信用信息的采集、查询和使用。地方金融机构可一站式接入使用，提升地方金融经营效率和风险管理水平。</p>
                                        <div class="login-btn">
                                            <a href="https://strainer.gzccb.cn/norns/service/cas/doLogin?service=https://strainer.gzccb.cn/odin/user/sso" target="_blank">登录信用滤网</a>
                                        </div>
                                    </div>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="区块链" name="third">
                                <div class="section-cont third-section">
                                    <div class="sec-top">
                                        <div class="banner-img-3"></div>
                                        <p>平台引入国家密码算法标准的区块链技术，通过数字存证、智能合约，共识协议、分布式账本服务，链上行为可追溯防篡改的特点，确保业务数据和业务流程安全可信。目前在数字存证、安全审计、供应链金融领域广泛应用。</p>
                                        <div class="login-btn">
                                            <a href="http://139.9.29.187:7061/mntopChain/#/login" target="_blank">平台演示</a>
                                        </div>
                                    </div>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="隐私计算" name="fourth">
                                <div class="section-cont fourth-section">
                                    <div class="sec-top">
                                        <div class="banner-img-4"></div>
                                        <p>平台利用隐私计算技术，逐步实现与政务数据、监管数据、司法数据、商业数据、地方金融机构间数据的安全共享，为数据要素市场化配置提供突破口。地方金融机构可依托基础设施平台，合法合规地调用各类外部数据资源和基于这些数据加工治理后的结果。同时可使用平台的隐私计算功能，与其他合作方实现数据可用不可见的联合建模和协同作业。</p>
                                        
                                    </div>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                    <div class="down-btn">
                        <a href="#section-sub-tab"><i class="el-icon-bottom"></i></a>
                    </div>
                </div>
                <div class="section-sub-tab" v-if="activeName=='first'" id="section-sub-tab">
                    <div class="sec-bot">
                        <div class="section-center">
                            <h4 class="sec-tit"><i class="el-icon-caret-right"></i><span>应用场景</span></h4>
                            <el-tabs v-model="activeName1" type="card" @tab-click="handleClick1">                            
                                <el-tab-pane v-for="(item,index) in subTab1" :key="index" :label="item.name" :name="String(index)">
                                    <div class="flex">
                                        <div class="l">
                                            <div>
                                                <h4>业务场景：</h4>
                                                <p>{{item.scence}}</p>
                                            </div>
                                            <div>
                                                <h4>业务价值：</h4>
                                                <p>{{item.value}}</p>
                                            </div>
                                        </div>
                                        <div class="r">
                                            <h4>{{item.imgTit}}</h4>
                                            <img :src="item.img" alt="">
                                        </div>
                                    </div>
                                </el-tab-pane>
                            </el-tabs>
                        </div>
                    </div>
                </div>
                <div class="section-sub-tab sub-second" v-if="activeName=='second'" id="section-sub-tab">
                    <div class="sec-bot">
                        <div class="section-center">
                            <h4 class="sec-tit"><i class="el-icon-caret-right"></i><span>数据资源目录</span></h4>
                            <ul class="scence-list flex">
                                <li>
                                    <div>
                                        <el-image
                                            :src="img1"
                                            :fit="fit">
                                        </el-image>
                                        <p>企业公开信息</p>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <el-image
                                            :src="img2"
                                            :fit="fit">
                                        </el-image>
                                        <p>企业进销项发票分析</p>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <el-image
                                            :src="img3"
                                            :fit="fit">
                                        </el-image>
                                        <p>企业财务数据分析</p>
                                    </div>
                                </li>
                            </ul>
                            <ul class="scence-list flex">
                                <li>
                                    <div>
                                        <el-image
                                            :src="img4"
                                            :fit="fit">
                                        </el-image>
                                        <p><span style="line-height: 28px;margin-top: 75px;">公用事业费用分析<br>（电力）</span></p>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <el-image
                                            :src="img5"
                                            :fit="fit">
                                        </el-image>
                                        <p>企业经营画像</p>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <el-image
                                            :src="img6"
                                            :fit="fit">
                                        </el-image>
                                        <p>电信运营商核验类</p>
                                    </div>
                                </li>
                            </ul>
                            <ul class="scence-list flex">
                                <li>
                                    <div>
                                        <el-image
                                            :src="img7"
                                            :fit="fit">
                                        </el-image>
                                        <p>企业主银行卡核验类</p>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <el-image
                                            :src="img8"
                                            :fit="fit">
                                        </el-image>
                                        <p>企业主收入能力类</p>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <el-image
                                            :src="img9"
                                            :fit="fit">
                                        </el-image>
                                        <p>企业主身份证信息核验类</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="section-sub-tab sub-third" v-if="activeName=='third'" id="section-sub-tab">
                    <div class="sec-bot">
                        <div class="section-center">
                            <h4 class="sec-tit"><i class="el-icon-caret-right"></i><span>应用场景</span></h4>
                            <el-tabs v-model="activeName2" type="card" @tab-click="handleClick1">                            
                                <el-tab-pane v-for="(item,index) in subTab2" :key="index" :label="item.name" :name="String(index)">
                                    <div class="flex">
                                        <div class="l">
                                            <div>
                                                <h4>业务场景：</h4>
                                                <p>{{item.scence}}</p>
                                            </div>
                                            <div>
                                                <h4>业务价值：</h4>
                                                <p>{{item.value}}</p>
                                            </div>
                                        </div>
                                        <div class="r">
                                            <h4 v-if="!(item.imgTit == '')">{{item.imgTit}}</h4>
                                            <img :src="item.img" alt="">
                                        </div>
                                    </div>
                                </el-tab-pane>
                            </el-tabs>
                        </div>
                    </div>
                </div>
                <div class="section-sub-tab" v-if="activeName=='fourth'" id="section-sub-tab">
                    <div class="sec-bot">
                        <div class="section-center">
                            <h4 class="sec-tit"><i class="el-icon-caret-right"></i><span>应用场景</span></h4>
                            <el-tabs v-model="activeName3" type="card">                            
                                <el-tab-pane v-for="(item,index) in subTab3" :key="index" :label="item.name" :name="String(index)">
                                    <div class="flex">
                                        <div class="l">
                                            <div>
                                                <h4>业务场景：</h4>
                                                <p>{{item.scence}}</p>
                                            </div>
                                            <div>
                                                <h4>业务价值：</h4>
                                                <p>{{item.value}}</p>
                                            </div>
                                        </div>
                                        <div class="r">
                                            <img :src="item.img" alt="">
                                        </div>
                                    </div>
                                </el-tab-pane>
                            </el-tabs>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    </div>
</template>

<script>
import { Header, Footer } from "../common/common.js";
import B1 from '../../assets/img/page3-1.png'
import B2 from '../../assets/img/page3-2.png'
import B3 from '../../assets/img/page3-3.png'
import B4 from '../../assets/img/page3-4.png'
import B5 from '../../assets/img/page3-5.png'
import img1 from '../../assets/img/img1.png'
import img2 from '../../assets/img/img2.png'
import img3 from '../../assets/img/img3.png'
import img4 from '../../assets/img/img4.png'
import img5 from '../../assets/img/img5.png'
import img6 from '../../assets/img/img6.png'
import img7 from '../../assets/img/img7.png'
import img8 from '../../assets/img/img8.png'
import img9 from '../../assets/img/img4.png'

  export default {
    components: {
        Header,
        Footer,
    },
    name: '',
    data () {
      return {
        img1: img1,
        img2: img2,
        img3: img3,
        img4: img4,
        img5: img5,
        img6: img6,
        img7: img7,
        img8: img8,
        img9: img9,
        fit: 'contain',
        activeName: 'first',
        subTab: [],
        activeName1: '0',
        subTab1: [
            {
                name: '金融园区数字化平台',
                scence: '根据广州市十四五规划要求，提升国资企业在上市综合服务、供应链金融、转贷和普惠金融等业务板块的数字化服务能力。',
                value: '金融园区数字化平台的建设，就是通过打造一个基于超级计算和大数据的人工智能决策系统——“XX智能引擎”，通过发挥“知识图谱、机器学习、区块链和隐私计算”等数字科技打造特色技术服务，以金融服务为核心逐步形成广州市各类产业链的智慧大脑，为政府、金融机构、企业、研究机构和行业组织提供智能决策支持服务。',
                imgTit: '金融园区数字化平台',
                img: B1
            },
            {
                name: '智能监管',
                scence: '国资委对央企国资企业加强数据安全共享利用推进重点监管业务数字化智能化提升，做到数据“采得尽、看得见、管得住、用得好”，为数字化智能化监管提供有力支撑和保障，进而提升国资监管专业化、体系化、法治化水平。',
                value: '对央企国资企业人员数据、业务数据、资金数据、企业固定资产等重点管理领域，实现了线上智能监管。通过该系统能够促进各央企国资企业单位协同共治、统筹资源、增加企业的透明度，保障国有企业关键环节的信息能够得到及时掌握，为管理和决策提供有效的数据支撑，提升国资监管专业化、体系化、法制化水平，进而提升监管的深度，实现更高水平数字化智能化监管。',
                imgTit: '政务资产智能监管平台',
                img: B2
            }
        ],
        activeName2: '0',
        subTab2: [
            {
                name: '惠农区块链数字化供应链金融',
                scence: '农企和农户存在贷款额度小、经营分散及可供抵押资产较少、缺失真实经营状况数据和无法评估其信用水平，往往难以满足传统金融机构信贷条件的要求，从而无法享受金融服务形成对产业的有效金融扶持及升级。',
                value: '以核心企业为主导，结合区块链技术自身不可篡改与数据可追溯的特性，通过将核心企业的应收账款进行数字化升级，使得应收账款可以作为信用凭证，在供应链中流转而传递给上游农户供应商。并通过整合资金方、担保融资租赁方、核心企业、供销商多方需求与资源，实现了农户应付账款电子凭证可以作为信用凭证进行流转、持有融资，为供应链条提供更低成本的资金支持，打造出更安全、更灵活、更高效的惠农金融区块链平台。',
                imgTit: '',
                img: B3
            },
            {
                name: '数字安全审计区块链',
                scence: '因系统内部的业务操作，越来越多的外部访问、内外部的数据交互等活动，增加了数据丢失、窃取和盗用风险，需对系统内部这些活动进行监控，及时发现异常和违规操作，最大程度减少因为系统内部原因引起的安全隐患。',
                value: '数据安全审计区块链，为业务系统量身打造的通用数据安全审计系统。旨在利用区块链的分布式存储技术、共识机制保障用户登录和操作行为日志等信息不可篡改，采用密码学技术保证被审计数据的安全和不可否认，结合时间戳服务实现被审计记录的可追溯，采用系统自动审计完成安全预警。为金融、政务、能源等领域提供全方位的安全审计方案。与某区区政务服务数据管理局合作，将基于区块链的数据安全审计系统成功应用到资源智慧管理平台（二期）、大数据中心（二期）、区政务OA系统中，利用区块链的特性，加强了政务信息系统的数据安全，防止业务信息系统的敏感信息泄露。',
                imgTit: '',
                img: B4
            }
        ],
        activeName3: '0',
        subTab3: [
            {
                name: '联合风控',
                scence: '小微商户风险问题已成为制约信贷发展的一个主要障碍，同时由于数据隐私安全保护的要求，跨企业的数据合作受到限制。因此中国银联电子支付研究院、天津大学+中山大学、微众银行通过合作，运用联邦学习技术，进行智能风控领域的落地应用研究。',
                value: '探索多方数据融合效果和联邦学习算法的可行性，并对小微企业进行多维度评价，优化企业风险授信模型。',
                imgTit: '',
                img: B5
            }
        ],
      }
    },
    mounted() {
        this.$nextTick(()=>{
            if(this.$route.query.sec){
                var sec = this.$route.query.sec
                this.activeName = sec
            }

            var video = $('video')[0];
            $("html").one('touchstart',function(){
                video.play();
            })
        })
    },
    methods: {
        handleClick() {
        },
        handleClick1() {
        }
    }
        

  }
</script>

<style>
    
    .page-3 .page-section .section-tab { 
        background: url('../../assets/img/BG4.jpg') no-repeat;
        background-size: 100% 100%;
        background-position: center;
        min-width: 1200px;
    }
    .page-3 .page-section .section-tab .el-tabs--card>.el-tabs__header {
        margin-bottom: 4.5556VH;
    }
    .page-3 .banner-img-1 {
        background: url('../../assets/img/page3-banner.png') no-repeat;
        height: 52.5740vh;
        background-size: contain;
        background-position: center;
    }
    .page-3 .banner-img-2 {
        background: url('../../assets/img/page3-banner2.png') no-repeat;
        height: 49.1667vh;
        background-size: contain;
        background-position: center;
    }
    .page-3 .banner-img-3 {
        background: url('../../assets/img/page3-banner3.png') no-repeat;
        height: 47.3148vh;
        background-size: contain;
        background-position: center;
        margin-top: 2vh;
    }
    .page-3 .banner-img-4 {
        background: url('../../assets/img/page3-banner4.png') no-repeat;
        height: 51.8518vh;
        background-size: contain;
        background-position: center;
        margin-top: 1vh;
    }
    .page-3 .page-section .section-tab .section-cont p {
        /* margin-top: 0; */
    }
    .page-3 .page-section .section-sub-tab {
        background: url('../../assets/img/BG2.png') no-repeat;
        background-size: 100% 100%;
        background-position: center;
        /* padding: 40px 0 60px; */
    }
    .page-3 .page-section .section-sub-tab .sec-bot .l {
        width: 42%;
    }
    .page-3 .page-section .section-sub-tab .sec-bot .r {
        width: 53%;
    }
    .page-3 .page-section .section-sub-tab .sec-bot .r h4 {
        font-weight: 700;
        font-size: 1.5rem;
        margin-top: 0;
    }
    .page-3 .page-section .section-sub-tab .scence-list {
        margin-bottom: 2.3148vh;
        justify-content: center;
    }
    .page-3 .page-section .section-sub-tab .scence-list>li:nth-of-type(2){
        margin: 0 25px;
    }
    .page-3 .page-section .section-sub-tab .scence-list>li>div {
        /* width: 383px; */
        text-align: center;
        position: relative;
        /* background:url('../../assets/img/B-5.png') no-repeat;  */
        /* background-size: 100% 100%; */
    }
    .page-3 .page-section .section-sub-tab .scence-list>li>div img {
        
        height: 24.1667vh;
    }
    .page-3 .page-section .section-sub-tab .scence-list li>div p {        
        position: absolute;
        top: 0;
        left: 0;
        font-size: 24px;
        font-weight: 700;
        color: #fff;
        line-height: 24.1667vh;
        width: 100%;
        margin: 0;
    }
    .page-section .section-sub-tab.sub-third .el-tabs--card>.el-tabs__header {
        margin-bottom: 5vh;
    }

</style>

