<template>
    <div :class="showBG?'common-head bg':'common-head'" id="common-head">
        <!-- <el-row :gutter="20"> -->
            <!-- <el-col :span="16" :offset="4"> -->
                <div class="flex-box">
                    <div class="logo"></div>
                    <div class="nav-list">
                        <ul class="el-menu-demo el-menu--horizontal el-menu">
                            <li :class="active==1?'el-menu-item is-active': 'el-menu-item'"><a href="/index/#section-A">首页</a></li>
                            <li :class="active==2?'el-menu-item is-active': 'el-menu-item'"><a href="/index/#section-B">平台服务</a></li>
                            <li @click="handleSelect('3')" @mouseover.stop="mouseO" @mouseleave="mouseL" :class="active==3?'el-menu-item sub-menu is-active': 'el-menu-item sub-menu'">
                                行业案例
                                <div class="cont-center">
                                    <div class="sub-menu-box flex" v-if="showMenu">
                                        <ul>普惠金融
                                            <li><a href="https://gzersp.gzjrfz.com/" target="_blank">转贷平台</a></li>
                                            <li><a href="https://zxr.gdjr.gd.gov.cn/portal/gz/static/main/index.html" target="_blank">中小融广州站</a></li>
                                        </ul>
                                        <ul>公共金融
                                            <li><a href="https://lms.jinfucloud.cn/#/login" target="_blank">小贷作业系统</a></li>
                                            <li><a href="" target="_blank">金羊数字化金融服务平台（建设中）</a></li>
                                        </ul>
                                        <ul>供应链金融
                                            <li><a href="http://159.75.22.181:9707/supplychain/login" target="_blank">惠农区块链数字化供应链金融平台</a></li>
                                        </ul>
                                        <ul>行业监管
                                            <li><a href="http://119.136.25.49:8090/auditblockchain/#/login" target="_blank">数字安全审计区块链</a></li>
                                            <li><a href="http://119.136.25.49:2080/jrguoziwei/#/login" target="_blank">政务资产智能监管平台</a></li>
                                        </ul>
                                    </div>
                                </div>
                                
                            </li>
                            <li :class="active==4?'el-menu-item is-active': 'el-menu-item'"><a @click="handleSelect('4')" href="/aboutus" target="_blank">关于平台</a></li>
                        </ul>
                    </div>
                </div>
            <!-- </el-col> -->
        <!-- </el-row> -->
        
    </div>
</template>
<script>
export default {
    props: {
        activeIndex: {
            type: String,
            default: '1'
        }
    },
    data() {
        return {
            active: '1',
            showBG: false,
            path: '',
            section: '',
            showMenu: false
        }
    },
    mounted() {
        this.$nextTick(()=>{
            this.active = this.activeIndex
            this.path = this.$route.path
            this.section = this.$route.params.section
            var B = document.getElementById('section-B')
            var that = this

            window.onscroll = function() {
                if(window.scrollY > 0) {
                    that.showBG = true
                }else{
                    that.showBG = false
                }
                
                if(that.path.includes('index')){
                    var Bt = B.getBoundingClientRect().top
                    if(window.scrollY > Bt || that.section == '2'){
                        that.active = "2"
                    }else {
                        that.active = "1"
                    }
                }
            }

            if(that.path.includes('index')){
                if(that.section == '2'){
                    document.querySelector("#section-B").scrollIntoView();
                }else {
                    document.querySelector("#section-A").scrollIntoView();
                }
            }
        })
    },
    methods: {
        handleSelect(key) {
            this.active = key
            if(this.path.includes('index')){
                if(key == 1){
                    document.querySelector("#section-A").scrollIntoView();
                }
                if(key == 2){
                    document.querySelector("#section-B").scrollIntoView();
                }
            }
            
        },
        test() {
            this.showMenu = true
        },
        mouseO() {
            this.showBG = true
            this.showMenu = true
        },
        mouseL() {
            // this.showBG = false
            this.showMenu = false
            if(window.scrollY > 0) {
                this.showBG = true
            }else{
                this.showBG = false
            }
        }
    }
}
</script>
<style>

    .common-head {
        height: 4.375rem;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 5;
        /* background: rgba(0,0,0,.8); */
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
    .common-head.bg {
        background: rgba(0,0,0,.8);
    }
    .flex-box {
        /* display: flex; */
        /* justify-content: space-between; */
        width: 1200px;
        margin: 0 auto;
        height: 100%;
    }
    .flex-box .logo {
        height: 100%;
        width: 22.5rem;
        background: url(../../assets/img/logo.png) no-repeat;
        background-size: contain;
        background-position-y: center;
        display: inline-block;
        /* margin-top: 1.5vh; */
    }
    .flex-box .nav-list {
        float: right;
        display: inline-block;
        /* padding-top: 10px; */
    }
    .flex-box .el-menu.el-menu--horizontal {
        background-color: unset !important;
        border: none !important;
    }
    .flex-box .el-menu.el-menu--horizontal .el-menu-item {
        background-color: unset !important;
        font-size: 1rem;
        font-weight: 700;
        margin: 0 1rem;
        padding: 0 0.625rem;
        color: #fff;
        height: 70px;
        line-height: 70px;
    }
    .flex-box .el-menu.el-menu--horizontal .el-menu-item.is-active {
        border-bottom-color: #fff !important;
        color: #fff !important;
    }
    .flex-box .el-menu.el-menu--horizontal .el-menu-item .sub-menu {
        position: relative;
    }
    .flex-box .el-menu.el-menu--horizontal .el-menu-item .cont-center {
        background: rgba(0,0,0,.8);
        position: fixed;
        left: 0;
        top: 70px;
        right: 0;
        /* border-top: 1px solid rgba(255, 255, 255, 0.1); */
    }
    .flex-box .el-menu.el-menu--horizontal .el-menu-item .sub-menu-box {
        width: 1350px;
        margin: 0 auto;
        justify-content: end;
        
    }
    .flex-box .el-menu.el-menu--horizontal .el-menu-item .sub-menu-box ul {
        padding: 30px 20px;
        color: #fff;
        line-height: 36px;
    }
    .flex-box .el-menu.el-menu--horizontal .el-menu-item .sub-menu-box ul li {
        height: 32px;
        line-height: 32px;
    }
    .flex-box .el-menu.el-menu--horizontal .el-menu-item .sub-menu-box ul li a {
        color: #fff;
        font-size: 14px;
        font-weight: 400;
    }
    .flex-box .el-menu.el-menu--horizontal .el-menu-item .sub-menu-box ul li a:hover {
        color: #3498ee;
    }
</style>