<template>
  <div id="app">
    <!-- <Index msg="Welcome to jinfuCloud"/> -->
    <router-view></router-view>
  </div>
</template>

<script>
// import Index from './components/index.vue'

export default {
  name: 'App',
//   components: {
//     Index
//   }
}
</script>

<style>
@font-face {
    font-family: 'iconfont';
    src: url('./assets/font_2911782_1sqo884q5ea/iconfont.woff2') format('woff2'),
        url('./assets/font_2911782_1sqo884q5ea/iconfont.woff') format('woff'),
        url('./assets/font_2911782_1sqo884q5ea/iconfont.ttf') format('truetype');
}
.iconfont {
    font-family: "iconfont" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
html , body {
    padding: 0;
    margin: 0;
    font-size: 16px;
    /* background: #151e2c; */
}
#app {
    width: 100%;
    height: 100%;
    font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* background: #151e2c; */
    /* max-width: 1920px; */
    margin: 0 auto;
    /* text-align: center; */
    /* color: #2c3e50; */
    /* margin-top: 60px; */
    min-width: 1200px;
}

a, button, em, img, input, label, span, strong {
    display: inline-block;
    text-decoration: none;
}
h1, h2, h3, h4, h5, h6 {
    font-weight: 100;
}
ul , li {
    list-style: none;
    padding: 0;
    margin: 0;
}
a {
    cursor: pointer;
    text-decoration: unset;
    display: block;
}
.hide {
    display: none;
}
.fw-700 {
    font-weight: 700
}
.flex {
    display: flex;
    justify-content: space-between;
}
.mb-10 {
    margin-bottom: 10px;
}
    .page {
        padding: 0;
        margin: 0;
        /* width: 100%; */
        /* height: 100vh; */
        /* min-height: calc(100vh - 346px); */
    }
    .page-section .section-center {
        width: 1200px;
        margin: 0 auto;     
        position: relative;
    }
    .page-section .section-tab {    
        padding: 10.6481VH 0 0;
        box-sizing: border-box;
        height: calc(100vh);    
        /* height: 86.7592vh; */
        position: relative;
    }
    .page-section .page-tit {
        position: absolute;
        top: -8px;
        color: #fff;
        font-size: 2.5rem;
        font-weight: 700;
        margin: 0;
    }
    .page-section .section-tab .el-tabs--card>.el-tabs__header {
        border-bottom: none;
        padding-left: 220px;
        margin-bottom: 5.5556VH;
    }
    .page-section .section-tab .el-tabs--card>.el-tabs__header .el-tabs__nav,
    .page-section .section-sub-tab .el-tabs--card>.el-tabs__header .el-tabs__nav {
        border: none;
    }
    .page-section .section-tab .el-tabs--card>.el-tabs__header .el-tabs__item.is-active,
    .page-section .section-tab .el-tabs--card>.el-tabs__header .el-tabs__item:hover {
        background: rgb(52, 152, 238);
    }
    .page-section .section-tab .el-tabs--card>.el-tabs__header .el-tabs__item:first-child {

    }
    .page-section .section-tab .el-tabs--card>.el-tabs__header .el-tabs__item {
        border: none;
        color: #fff;
        background: rgba(26, 97, 159,.4);
        margin: 0 10px;
        border-radius: 4px;
        padding: 0 40px;
        font-size: 16px;
    }
    .page-section .section-tab .section-cont p {
        color: #fff;
        font-size: 16px;
        text-indent: 35px;
        margin-top: 5VH;
        line-height: 1.5;
    }
    .page-section .section-sub-tab {
        background: url('./assets/img/BG2.png') no-repeat;
        background-size: 100% 100%;
        background-position: center;
        min-width: 1200px;
        /* padding: 40px 0 60px; */
    }
    .page-section .section-sub-tab .sec-tit {
        font-size: 1.5rem;
        font-weight: 700;
        color: #041e38;
        margin-top: 0;
    }
    .page-section .section-sub-tab .sec-tit i {
        position: relative;
        font-size: 2.5rem;
        /* line-height: 24px; */
        color: #3e91e0;
        vertical-align: middle;
        margin-left: -9px;
    }
    .page-section .section-sub-tab .sec-tit i:after {
        position: absolute;
        content: '';
        display: inline-block;
        left: 9px;
        top: 8px;
        width: 3px;
        height: 24px;
        background: #3e91e0;
    }
    .page-section .section-sub-tab .sec-tit span {
        vertical-align: middle;
    }
    .page-section .section-sub-tab .el-tabs--card>.el-tabs__header {
        border-bottom: none;
    }
    .page-section .section-sub-tab .el-tabs--card>.el-tabs__header .el-tabs__item {
        border: 1px solid #3498ee;
        color: #3498ee;
        background: #fff;
        margin: 0 20px 0 0;
        border-radius: 4px;
        padding: 0 40px;
        font-size: 1rem;
    }
    .page-section .section-sub-tab .el-tabs--card>.el-tabs__header .el-tabs__item.is-active,
    .page-section .section-sub-tab .el-tabs--card>.el-tabs__header .el-tabs__item:hover {
        background: rgb(52, 152, 238);
        color: #fff;
    }
    .page-section .section-sub-tab .sec-bot {
        padding: 7.4074vh 0;
        /* background: #f8f8f8; */
    }
    .page-section .section-sub-tab .sec-bot .flex {
        justify-content: space-between;
        align-items:center;
    }
    .page-section .section-sub-tab .sec-bot .l,
    .page-section .section-sub-tab .sec-bot .r {
        width: 45%;
        padding: 0;
    }
    .page-section .section-sub-tab .sec-bot .l {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    .page-section .section-sub-tab .sec-bot .l>div:nth-of-type(2) {
        margin: 5VH 0 0;
    }
    .page-section .section-sub-tab .sec-bot .l>div h4 {
        font-size: 1.125rem;
        font-weight: 700;
        color: #000;
        margin-bottom: 1.3889VH;
        margin-top: 0;
    }
    .page-section .section-sub-tab .sec-bot .l>div p {
        font-size: 16px;
        color: #000;
        margin-bottom: 0;
        line-height: 1.5;
    }
    .page-section .section-sub-tab .sec-bot .r {
        text-align: center;
        /* position: relative; */
    }
    
    .login-btn {
        width: 200px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        border-radius: 6px;
        margin: 2.1481vh auto 3.5185vh;
        background: #3498ef;
        font-size: 16px;
    }
    .login-btn a {
        color: #fff;
    }
    .down-btn {
        width: 36px;
        height: 36px;
        text-align: center;
        line-height: 36px;
        border-radius: 50%;
        margin: 0 auto 0;
        font-size: 2rem;
        border: 2px solid #fff;
        cursor: pointer;
        animation: 1.5s updown infinite ease-in-out alternate;
        position: absolute;
        left: 50%;
        bottom: 6.8889vh;
        transform: translateX(-50%);
        z-index: 2;
    }
    .down-btn a {
        color: #fff;
    }
    @keyframes updown {
        0% {
            bottom: 6.8889vh;
        }
        100% {
            bottom: 4.8889vh;
        }
    }
</style>
