<template>
    <div class="layout">
        <div class="page-aboutus page">
            <Header :activeIndex="'4'"></Header>
            <div class="page-section">
                <div class="section-tab">
                    <div class="section-center">   
                        <div class="section-cont first-section">
                            <div class="banner-txt">
                                <i class="up"></i>
                                <h4>地方金融数字化基础设施平台</h4>
                                <p>为金融机构数字化转型提供一站式解决方案</p>
                                <ul class="flex">
                                    <li>机构市场开拓</li>
                                    <li>业务运营</li>
                                    <li>风险管理</li>
                                    <li>数据资源</li>
                                    <li>云计算资源</li>
                                </ul>                                
                                <i class="down"></i>
                            </div>
                        </div>  
                    </div>                      
                    <div class="down-btn">
                        <a href="#section-sub-tab"><i class="el-icon-bottom"></i></a>
                    </div>
                </div>
                <div class="section-sub-tab" id="section-sub-tab">
                    <div class="sec-bot">
                        <div class="section-center">
                            <h4 class="sec-tit"><i class="el-icon-caret-right"></i><span>平台简介</span></h4>
                            <p style="text-indent: 35px;font-size:1rem;line-height: 1.8;">地方金融数字化基础设施平台是为各类地方金融机构提供数字化转型服务的技术平台，为地方金融机构的市场开拓、业务运营、风险管理和数据获取等核心业务提供通用解决方案、软件系统、数据资源和计算资源服务。重点解决单个地方金融机构数字化转型能力弱和地方金融行业缺乏有效的数据安全共享机制难题。充分发挥地方金融数字化公共基础设施服务职能，为地方金融行业在普惠金融、供应链金融、公共金融服务、行业监管等核心领域升级赋能。</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    </div>
</template>

<script>
import { Header, Footer } from "../common/common.js";

  export default {
    components: {
        Header,
        Footer,
    },
    name: '',
    data () {
      return {
        
      }
    },
    mounted() {
        this.$nextTick(()=>{
            
        })
    },
    methods: {}
        

  }
</script>

<style>

    .page-aboutus .page-section .section-tab {        
        /* position: relative; */
        padding: 19.9074vh 0 0;
        background: url('../../assets/img/about-banner.png') no-repeat;
        background-size: 100% 100%;
        background-position: center;
        min-width: 1200px;
    }
    .page-aboutus .page-section .banner-txt {
        width: 100%;
        height: 38.4259vh;
        margin: 0 auto 18.9815vh;
        background: rgba(1, 64, 185,.4);
        text-align: center;
        padding: 9.074vh 0 0;
        position: relative;
}
    .page-aboutus .page-section .banner-txt h4 {
        color: #fff;
        font-size: 3.75rem;
        margin: 0;
        font-weight: 900;
    }
    .page-aboutus .page-section .banner-txt p {
        color: #fff;
        font-size: 2.5rem;
        margin: 2vh auto 6.6926vh;
    }
    .page-aboutus .page-section .banner-txt ul {
        justify-content: center;
    }
    .page-aboutus .page-section .banner-txt ul li {
        width: 220px;
        height: 6.2264vh;
        line-height: 6.2264vh;
        font-size: 1.5rem;
        padding-left: 20px;
        box-sizing: border-box;
        text-align: left;
        color: #fff;
        background: linear-gradient(90deg,#3e91e0,rgba(0,0,0,0));
    }
    
    .page-aboutus .page-section .section-sub-tab .sec-bot {
        padding: 7.4074vh 0 14.8148vh;
        /* background: #f8f8f8; */
    }
    .up {
        position: absolute;
        top: 0;
        left: 0;
    }
    .down {
        position: absolute;
        bottom: 0;
        right: 0;
    }
    .up:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 25px;
        height: 3px;
        background: #fff;
    }
    .up:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 3px;
        height: 25px;
        background: #fff;
    }
    .down:before {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 25px;
        height: 3px;
        background: #fff;
    }
    .down:after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 3px;
        height: 25px;
        background: #fff;
    }
</style>

