<template>
    <div class="layout">
        <div class="page-2 page">
            <Header :activeIndex="'2'"></Header>
            <div class="page-section">
                <div class="section-tab">
                    <div class="section-center">   
                        <h4 class="page-tit">应用响应层</h4>                     
                        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">                            
                            <el-tab-pane label="智能风控" name="first">
                                <div class="section-cont first-section">
                                    <div class="sec-top">
                                        <div class="banner-img-1">
                                            <div class="TXT"></div>
                                            <div class="turnning"></div>
                                        </div>
                                        <p>地方金融区块链征信共享平台结合三方数据源，实现与广州市小额贷款行业非现场监管数据共享、信用风险联合建模。引入区块链技术，将企业信用画像、企业授权、机构查询行为上链，协助监管部门规范金融信用信息的采集、查询和使用，提升风险管理水平。为地方金融机构、监管部门通过平台共享借款主体信用表现，提升风控手段和风险定价能力。</p>
                                        <div class="login-btn">
                                            <a href="https://xy.gzccb.cn/login?redirect=%2Fproduct%2Finfo" target="_blank">登录智能风控平台</a>
                                        </div>

                                    </div>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="数据资产管理" name="second">
                                <div class="section-cont second-section">
                                    <div class="sec-top">
                                        <div class="banner-img-2">
                                        </div>
                                        <p>通过平台数据治理服务，帮助企业、机构、政府部门形成数据资产，并通过可视化大屏、统一审计、风险监控、指数体系、质量管控、绩效评估等应用组件进行数据资产管理。</p>
                                    </div>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="数字化作业" name="third">
                                <div class="section-cont third-section">
                                    <div class="sec-top">
                                        <div class="banner-img-3">
                                            <video autoplay loop muted controls>
                                                <!-- <source src="https://h5-uat.gzszxr.cn/stage-api/common/ossDownload?resource=common/video/jinfucloud_introduce.mp4" type="video/mp4"> -->
												<source src="../../assets/mp4/jinfucloud_introduce.mp4" type="video/mp4">
                                                您的浏览器不支持Video标签。
                                            </video>
                                        </div>
                                        <p>数字化作业系统助力地方金融机构实现全业务流程的数字化运作，并逐步搭建依托数据的业务分析和风控管理体系。服务基于SaaS架构，有助于地方金融机构大幅降低信息化的软硬件投入成本，快速升级地方金融机构数字化运营能力，真正实现降本增效。</p>
                                        <div class="login-btn">
                                            <a href="https://lms.jinfucloud.cn/#/dashboard/index" target="_blank">登录数字化作业平台</a>
                                        </div>

                                    </div>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="数字签章" name="fourth">
                                <div class="section-cont fourth-section">
                                    <div class="sec-top">
                                        <div class="flex">
                                            <div class="l">
                                                <h4>区块链存证 - 电子签章平台</h4>
                                                <p>数字签章平台，是基于区块链技术的新型数字存证服务。存证过的合同文件都可开具可信的公证书。客户如需诉讼举证，可以通过数字签章平台向公证处进行出证申请，经过公证处出证的合同，法院将直接采信，全流程拥有法律保障。</p>
                                                <div class="login-btn">
                                                    <a href="https://esign.gzccb.cn/login?service=http%3A%2F%2Fesign.gzccb.cn%2F" target="_blank">登录数字签章平台</a>
                                                </div>
                                            </div>
                                            <div class="r">
                                                <div class="banner-img-4"></div>
                                            </div>
                                        </div>
                                        
                                        <p>帮助地方金融机构实现业务合同全程电子化签署，通过线上多方电子签约—区块链数据存证技术，确保合同不可篡改。</p>
                                    </div>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                    <div :class="activeName=='third'?'down-btn hide':'down-btn'">
                        <a href="#section-sub-tab"><i class="el-icon-bottom"></i></a>
                    </div>
                </div>
                <div class="section-sub-tab sub-first" v-if="activeName=='first'" id="section-sub-tab">
                    <div class="section-center">
                        <div class="sec-bot">
                            <h4 class="sec-tit"><i class="el-icon-caret-right"></i><span>应用场景</span></h4>
                            <ul class="scence-list flex">
                                <li>
                                    <div>
                                        <el-image
                                            :src="img1"
                                            :fit="fit">
                                        </el-image>
                                        <p>小贷行业</p>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <el-image
                                            :src="img2"
                                            :fit="fit">
                                        </el-image>
                                        <p>银行</p>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <el-image
                                            :src="img3"
                                            :fit="fit">
                                        </el-image>
                                        <p>商业保理</p>
                                    </div>
                                </li>
                            </ul>
                            <ul class="scence-list flex">
                                <li>
                                    <div>
                                        <el-image
                                            :src="img4"
                                            :fit="fit">
                                        </el-image>
                                        <p>融资担保</p>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <el-image
                                            :src="img5"
                                            :fit="fit">
                                        </el-image>
                                        <p>融资租赁</p>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <el-image
                                            :src="img6"
                                            :fit="fit">
                                        </el-image>
                                        <p>证券</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="section-sub-tab sub-second" v-if="activeName=='second'" id="section-sub-tab">
                    <div class="section-center">
                        <div class="sec-bot">
                            <h4 class="sec-tit"><i class="el-icon-caret-right"></i><span>应用场景</span></h4>
                            <el-tabs v-model="activeName1" type="card">                            
                                <el-tab-pane v-for="(item,index) in subTab1" :key="index" :label="item.name" :name="String(index)">
                                    <div class="flex">
                                        <div class="l">
                                            <div>
                                                <h4>业务场景：</h4>
                                                <p>{{item.scence}}</p>
                                            </div>
                                            <div>
                                                <h4>业务价值：</h4>
                                                <p>{{item.value}}</p>
                                            </div>
                                        </div>
                                        <div class="r">
                                            <img :src="item.img" alt="">
                                        </div>
                                    </div>
                                </el-tab-pane>
                            </el-tabs>
                        </div>
                    </div>
                </div>
                <div class="section-sub-tab sub-third" v-if="activeName=='third'" id="section-sub-tab">
                    <div class="sec-bot">
                        <div class="top">
                            <div class="section-center">
                                <h4 class="sec-tit"><i class="el-icon-caret-right"></i><span>优势</span></h4>
                                <div style="text-align: center;"><img src="../../assets/img/page2-2.png" style="max-width:1200px;max-height: 44.3435vh;" alt=""></div>
                            </div>
                        </div>
                        <div class="bot">
                            <div class="section-center">
                                <h4 class="sec-tit"><i class="el-icon-caret-right"></i><span>系统架构</span></h4>
                                <div style="text-align: center;margin: 3vh 0"><img src="../../assets/img/page2-3.png" style="max-width:1200px;max-height: 52.8704vh;" alt=""></div>
                                <h4 class="sec-tit"><i class="el-icon-caret-right"></i><span>应用场景</span></h4>
                                <ul class="scence-list flex">
                                    <li>
                                        <div>
                                            <el-image
                                                :src="img1"
                                                :fit="fit2">
                                            </el-image>
                                            <p>小贷行业</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <el-image
                                                :src="img4"
                                                :fit="fit2">
                                            </el-image>
                                            <p>融资担保</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <el-image
                                                :src="img3"
                                                :fit="fit2">
                                            </el-image>
                                            <p>商业保理</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <el-image
                                                :src="img5"
                                                :fit="fit2">
                                            </el-image>
                                            <p>融资租赁</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="section-sub-tab sub-fourth" v-if="activeName=='fourth'" id="section-sub-tab">                    
                        <div class="sec-bot">
                            <div class="top">
                                <div class="section-center">
                                    <h4>数字签章平台的保障：</h4>
                                    <p>（1）通过数字签章平台实现异地远程签署；</p>
                                    <p>（2）与防伪打印机对接实现电子合同纸质输出；</p>
                                    <p>（3）通过数字签章平台合同台账管理实现签署完成合同归档；</p>
                                    <p>（4）海内外个人/企业的身份认证及CA证书颁发；</p>
                                    <h4>数字签章平台带来的效益：</h4>
                                    <p>（一）降低合同签署难度</p>
                                    <p class="mb-8">业务合同往往会涉及多方的签署主体，多个签署主体之间地理位置分散，传统纸质合同签署难度大，效率低。电子合同签约可支持八方甚至更多的主体同时签署一份合同。</p>
                                    <p>（二）解决法律风险</p>
                                    <p class="mb-8">传统纸质合同涉及主体多、签署流程长，合同的法律有效性及防篡改性有很大风险。电子签约则通过区块链技术存证确保电子合同不被篡改，有效保障各方合法效益。</p>
                                    <p>（三）减少人工工作量</p>
                                    <p class="mb-8">纸质合同涉及到合同内容审核、流程审批、邮寄、合同归档等内容，工作量巨大。电子签约，线上存在归档，有效减少人工和时间成本。</p>
                                
                                </div>
                            </div>
                            <div class="bot">
                                <div class="section-center">
                                    <h4 class="sec-tit"><i class="el-icon-caret-right"></i><span>应用场景</span></h4>
                                    <ul class="scence-list flex">
                                        <li>
                                            <div>
                                                <el-image
                                                    :src="img1"
                                                    :fit="fit2">
                                                </el-image>
                                                <p>小贷行业</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div>
                                                <el-image
                                                    :src="img4"
                                                    :fit="fit2">
                                                </el-image>
                                                <p>融资担保</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div>
                                                <el-image
                                                    :src="img3"
                                                    :fit="fit2">
                                                </el-image>
                                                <p>商业保理</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div>
                                                <el-image
                                                    :src="img5"
                                                    :fit="fit2">
                                                </el-image>
                                                <p>融资租赁</p>
                                            </div>
                                        </li>
                                    </ul>
                                    <ul class="scence-list flex">
                                        <li>
                                            <div>
                                                <el-image
                                                    :src="img2"
                                                    :fit="fit2">
                                                </el-image>
                                                <p>供应链行业</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div>
                                                <el-image
                                                    :src="img6"
                                                    :fit="fit2">
                                                </el-image>
                                                <p>零售制造</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div>
                                                <el-image
                                                    :src="img7"
                                                    :fit="fit2">
                                                </el-image>
                                                <p>IT互联网</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div>
                                                <el-image
                                                    :src="img8"
                                                    :fit="fit2">
                                                </el-image>
                                                <p>工程</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    </div>
</template>

<script>
import { Header, Footer } from "../common/common.js";
import B1 from '../../assets/img/page2-1.png'
import img1 from '../../assets/img/img1.png'
import img2 from '../../assets/img/img5.png'
import img3 from '../../assets/img/img3.png'
import img4 from '../../assets/img/img2.png'
import img5 from '../../assets/img/img4.png'
import img6 from '../../assets/img/img6.png'
import img7 from '../../assets/img/img7.png'
import img8 from '../../assets/img/img8.png'
import img9 from '../../assets/img/img9.png'

  export default {
    components: {
        Header,
        Footer,
    },
    name: '',
    data () {
      return {
        img1: img1,
        img2: img2,
        img3: img3,
        img4: img4,
        img5: img5,
        img6: img6,
        img7: img7,
        img8: img8,
        img9: img9,
        fit: 'contain',
        fit2: 'cover',
        activeName: 'first',
        activeName1: '0',
        subTab1: [
            {
                name: '政务资产智能监管平台',
                scence: '国资委对国资央企加强数据安全共享利用推进重点监管业务数字化智能化提升，做到数据“采得尽、看得见、管得住、用得好”，为数字化智能化监管提供有力支撑和保障，进而提升国资监管专业化、体系化、法治化水平。',
                value: '对央企国资企业人员数据、业务数据、资金数据、企业固定资产等重点管理领域，实现线上智能监管。通过该系统能够促进各央企国资企业单位协同共治、统筹资源、增加企业的透明度，保障国有企业关键环节的信息能够得到及时掌握，为管理和决策提供有效的数据支撑，提升国资监管专业化、体系化、法制化水平，进而提升监管的深度，实现更高水平数字化智能化监管。',
                img: B1
            }
        ],
      }
    },
    mounted() {
        this.$nextTick(()=>{
            if(this.$route.query.sec){
                var sec = this.$route.query.sec
                this.activeName = sec
            }

            var video = $('video')[0];
            $("html").one('touchstart',function(){
                video.play();
            })
        })
    },
    methods: {
        handleClick() {
            
        },
    }
        

  }
</script>

<style>
    
    .page-2 .page-section .section-tab {  
        background: url('../../assets/img/BG.jpg') no-repeat;
        background-size: 100% 100%;
        background-position: center;
        min-width: 1200px;
    }
    .page-2 .page-section .section-tab .el-tabs--card>.el-tabs__header {
        margin-bottom: 3.5vh;
    }

    .page-2 .banner-img-1 {
        height: 48.7619VH;
        background: url('../../assets/img/page2-banner.png') no-repeat;
        background-size: contain;
        background-position: center;
        position: relative;
    }
    .page-2 .banner-img-1 .TXT {
        width: 14.5370vh;
        height: 5.2778vh;
        background: url('../../assets/img/txt.png') no-repeat;
        background-size: contain;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50.5%,-43%);
    }
    .page-2 .banner-img-1 .turnning {
        width: 20.6481vh;
        height: 20.6481vh;
        background: url('../../assets/img/turn.png') no-repeat;
        background-size: contain;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50.5%,-49.5%);        
        animation: myfirst2 6s infinite linear;
    }
    @keyframes myfirst2{
        from {
            transform: translate(-50.5%,-49.5%) rotate(0deg);
        }
        to {
            transform: translate(-50.5%,-49.5%) rotate(359deg);
        }
    }
    
    @keyframes myfirst {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(-359deg);
        }
    }
    .page-2 .banner-img-2 {
        height: 51.8518VH;
        background: url('../../assets/img/page2-banner2.png') no-repeat;
        background-size: contain;
        background-position: center;
    }
    .page-2 .banner-img-3 {
        height: 63.8518VH;
        position: relative;
    }
    .page-2 .banner-img-3 video {
        /* width: 100%; */
        height: 100%;
        /* height: 51.8518VH; */
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%; 
        -o-object-fit: fill;
        object-fit: fill;
        //width: auto;
        width: 100%;
        //height: auto;
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
    }
    .page-2 .page-section .third-section .sec-top p {
        margin-top: 4vh;
    }
    .page-2 #pane-fourth .sec-top .flex {
        align-items: center;
    }
    .page-2 .banner-img-4 {
        height: 51.8518VH;
        background: url('../../assets/img/page2-4.png') no-repeat;
        background-size: contain;
        background-position: center;
    }
    .page-2 #pane-fourth .sec-top .flex>div {
        width: 45%;
    }
    .page-2 #pane-fourth .sec-top .flex .l h4 {
        font-size: 30px;
        font-weight: 400;
        color: #fff;
    }
    .page-2 #pane-fourth .sec-top .flex .l p {
        line-height: 1.8;
    }
    .page-2 #pane-fourth .sec-top .flex .l .login-btn {
        margin: 4.1481vh 0 0;
    }
    .page-2 .page-section .section-sub-tab .sec-bot {        
        padding: 0;
    }
    .page-2 .page-section .section-sub-tab .sec-bot .l {
        width: 42%;
    }
    .page-2 .page-section .section-sub-tab .sec-bot .r {
        width: 53%;
    }
    .page-2 .page-section .section-sub-tab .scence-list>li>div {
        text-align: center;
        position: relative;
    }
    .page-2 .page-section .section-sub-tab .scence-list li>div p {        
        position: absolute;
        top: 0;
        left: 0;
        font-size: 24px;
        font-weight: 700;
        color: #fff;
        line-height: 24.1667vh;
        width: 100%;
        margin: 0;
    }
    .page-2 .page-section .section-sub-tab.sub-first,
    .page-2 .page-section .section-sub-tab.sub-second {
        padding: 7.4074vh 0;
    }
    .page-2 .page-section .section-sub-tab.sub-first .scence-list {
        margin-top: 2.3148vh;
        justify-content: center;
    }
    .page-2 .page-section .section-sub-tab.sub-first .scence-list>li:nth-of-type(2){
        margin: 0 25px;
    }
    .page-2 .page-section .section-sub-tab.sub-first .scence-list>li>div img {
        height: 24.1667vh;
    }
    .page-2 .page-section .section-sub-tab.sub-third {
        background: #fff;
    }
    .page-2 .section-sub-tab.sub-third .top {
        /* height: 71.9444vh; */
        background: url('../../assets/img/BG5.png') no-repeat;
        background-size: 100% 100%;
        background-position: center;
        padding: 7.4074vh 0;
    }
    .page-2 .section-sub-tab.sub-third .top img {
        /* height: 51.1111vh; */
        margin-top: 5vh;
    }
    .page-2 .section-sub-tab.sub-third .bot {
        background: url('../../assets/img/BG2.png') no-repeat;
        background-size: 100% 100%;
        background-position: center;
        padding: 7.4074vh 0;
    }
    .page-2 .section-sub-tab.sub-third .scence-list>li {
        /* width: 286px;
        height: 260px; */
        /* height: 27.0741vh; */
        margin-right: 20px;
    }    
    .page-2 .section-sub-tab.sub-third .scence-list>li .el-image,
    .page-2 .section-sub-tab.sub-fourth .scence-list>li .el-image {
        width: 286px;
        height: 260px;
    }
    .page-2 .section-sub-tab.sub-third .scence-list>li:nth-of-type(4),
    .page-2 .section-sub-tab.sub-fourth .scence-list>li:nth-of-type(4) {
        margin-right: 0;
    } 
    .page-2 .page-section .section-sub-tab.sub-third .scence-list li>div p,
    .page-2 .page-section .section-sub-tab.sub-fourth .scence-list li>div p {
        line-height: 260px;
    }
    .page-2 .section-sub-tab.sub-fourth {
        background: #fff;
    }
    .page-2 .page-section .section-sub-tab.sub-fourth .scence-list {
        margin-bottom: 12px;
    }
    .page-2 .section-sub-tab.sub-fourth .scence-list>li>div {
        width: 290px;
    }
    .page-2 .section-sub-tab.sub-fourth .top {
        /* height: 71.9444vh; */
        background: url('../../assets/img/BG2.png') no-repeat;
        background-size: 100% 100%;
        padding: 7.4074vh 0;
        /* margin-bottom: 7.4074vh; */
    }
    .page-2 .section-sub-tab.sub-fourth .top h4 {
        font-size: 18px;
        font-weight: 700;
        margin: 0 0 15px;
    }
    .page-2 .section-sub-tab.sub-fourth .top p {
        font-size: 16px;
        margin: 0;
        line-height: 24px;
    }
    .page-2 .section-sub-tab.sub-fourth .top .mb-8 {
        margin-bottom: 8px;
    }
    .page-2 .section-sub-tab.sub-fourth .bot {
        padding: 7.4074vh 0;
    }
</style>

