import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import 'fullpage.js/vendors/scrolloverflow';
import VueFullPage from 'vue-fullpage.js';
import router from './router/router.js'
// import $ from "jquery"

router.afterEach(() => {
    // console.log(to,from)
    window.scrollTo(0,0);
});

Vue.config.productionTip = false

Vue.use(ElementUI);
Vue.use(VueFullPage);
// Vue.use($);

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
